var isDev = process.env.NODE_ENV === 'development';
export var tableMock = isDev ? [{
  baseFileName: '我是文件A',
  questionNum: 10,
  targetFileName: '我是文件AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
  canCompare: true
}, {
  baseFileName: '我是文件B',
  questionNum: 110,
  targetFileName: '我是文件BA',
  canCompare: false
}, {
  baseFileName: '我是文件B',
  questionNum: 110,
  targetFileName: '我是文件BA',
  canCompare: false
}, {
  baseFileName: '我是文件B',
  questionNum: 110,
  targetFileName: '我是文件BA',
  canCompare: false
}, {
  baseFileName: '我是文件B',
  questionNum: 110,
  targetFileName: '我是文件BA',
  canCompare: false
}] : [];
export default isDev ? [{
  label: '一级 1',
  children: [{
    label: '二级 1-1',
    children: [{
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1-1-1-1-1-1-1--1-1-1-1--1-1-1-1-1-1-1-1-1-'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }, {
      label: '三级 1-1-1'
    }]
  }]
}, {
  label: '一级 2',
  children: [{
    label: '二级 2-1',
    children: [{
      label: '三级 2-1-1'
    }]
  }, {
    label: '二级 2-2',
    children: [{
      label: '三级 2-2-1'
    }]
  }]
}, {
  label: '一级 3',
  children: [{
    label: '二级 3-1',
    children: [{
      label: '三级 3-1-1'
    }]
  }, {
    label: '二级 3-2',
    children: [{
      label: '三级 3-2-1'
    }]
  }]
}, {
  label: '一级 4'
}, {
  label: '一级 5'
}] : [];