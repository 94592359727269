var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.compareLoading,
          expression: "compareLoading"
        }
      ],
      staticClass: "preReviewContainer"
    },
    [
      _c("div", { staticClass: "versionTree" }, [
        _c("span", { staticClass: "title-span" }, [_vm._v("请选择基础版本")]),
        _c(
          "div",
          { staticClass: "baseVersion el-tree-maia-group" },
          [
            _c("el-tree", {
              ref: "stree",
              staticClass: "el-tree--maia",
              attrs: {
                data: _vm.data,
                "show-checkbox": "",
                "node-key": "folderID",
                load: _vm.loadNode,
                lazy: "",
                props: _vm.defaultProps
              },
              on: {
                "node-expand": _vm.baseExpand,
                "node-collapse": _vm.baseCollapse,
                check: _vm.sourceCheck
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "versionTree" }, [
        _c("span", { staticClass: "title-span" }, [_vm._v("请选择对比版本")]),
        _c(
          "div",
          { staticClass: "compareVersion el-tree-maia-group" },
          [
            _c("el-tree", {
              ref: "ttree",
              staticClass: "el-tree--maia",
              attrs: {
                data: _vm.data,
                "default-expanded-keys": _vm.targetExpanded,
                "show-checkbox": "",
                "node-key": "folderID",
                load: _vm.loadNode,
                lazy: "",
                props: _vm.defaultProps
              },
              on: { check: _vm.targetCheck }
            })
          ],
          1
        )
      ]),
      _c(
        "p",
        { staticClass: "cmpBtn" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "maiaPrimary",
                size: "medium",
                loading: _vm.cmpLoading
              },
              on: { click: _vm.compare }
            },
            [_vm._v("进行版本比对")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "cmpRes" }, [
        _c("span", { staticClass: "title-span" }, [_vm._v(" 文件查找结果：")]),
        _c("div", { staticClass: "cmpRes-content" }, [
          !_vm.hasCompareInfo
            ? _c(
                "div",
                { staticClass: "emptyBox" },
                [
                  _c("el-empty", {
                    staticStyle: { "text-align": "center" },
                    attrs: { "image-size": 200, description: "暂无审核记录" }
                  })
                ],
                1
              )
            : _c("div", { staticClass: "cmpResDetail" }, [
                _c("div", { staticClass: "cmpResDetail-header" }, [
                  _c("div", { staticClass: "compareDetail" }, [
                    _c("div", { staticClass: "compareDetail-box" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(_vm._s(_vm.preInfo.totalProblemNum))
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v("问题总数")])
                    ]),
                    _c("div", { staticClass: "compareDetail-box" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(_vm._s(_vm.preInfo.problemFileNum))
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v("存在问题文件数")])
                    ]),
                    _c("div", { staticClass: "compareDetail-box" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(_vm._s(_vm.preInfo.unableFileNum))
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v("无法对比")])
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "compare-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "maiaPrimary", size: "medium" },
                          on: { click: _vm.startSmartAudit }
                        },
                        [_vm._v("开始智能审核")]
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "container-el-table" },
                  [
                    _c(
                      "el-table",
                      {
                        staticClass: "is-grey",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.preInfo.details,
                          border: "",
                          "row-key": function(row) {
                            return row.baseFileName
                          }
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "baseFileName",
                            label: "基础版本",
                            width: "180"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "problemNum",
                            label: "问题数量",
                            width: "80"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "targetFileName", label: "比对版本" }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "查询结果", width: "100" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.canCompare === true
                                          ? "可以对比"
                                          : "无法对比"
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "操作" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "span",
                                          {
                                            class: scope.row.canCompare
                                              ? "maia-blue-pointer"
                                              : "maia-red-pointer",
                                            on: {
                                              click: function($event) {
                                                return _vm.operation(scope.row)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.canCompare
                                                  ? "放弃对比"
                                                  : "重新选择"
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        title: "选择新的文件",
                                        visible: _vm.dialogVisible,
                                        width: "35%"
                                      },
                                      on: {
                                        "update:visible": function($event) {
                                          _vm.dialogVisible = $event
                                        }
                                      }
                                    },
                                    [
                                      _c("inline-tree", {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.inlineLoading,
                                            expression: "inlineLoading"
                                          }
                                        ],
                                        ref: "inlineTree",
                                        attrs: { data: _vm.inlineData }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "dialog-footer",
                                          attrs: { slot: "footer" },
                                          slot: "footer"
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function($event) {
                                                  _vm.dialogVisible = false
                                                }
                                              }
                                            },
                                            [_vm._v("取 消")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.chooseOneInlineNode()
                                                }
                                              }
                                            },
                                            [_vm._v("确 定")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }