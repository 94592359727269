var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pre-review-core", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.treeLoading,
        expression: "treeLoading"
      }
    ],
    attrs: { data: _vm.procedureData }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }