var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inlineData" }, [
    !_vm.hasData
      ? _c("div", { staticClass: "data-detail" }, [
          _c("span", [_vm._v("暂无数据")])
        ])
      : _c(
          "div",
          { staticClass: "data-tree" },
          [
            _c("el-tree", {
              ref: "inlinetree",
              staticClass: "el-tree--maia",
              attrs: {
                data: _vm.data,
                "show-checkbox": "",
                "node-key": "folderID",
                props: _vm.defaultProps
              },
              on: { check: _vm.checkone }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }