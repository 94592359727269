import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
var SMART_RECHECK_BASE_URL = '/app-doc-center/v1/smart-recheck';
export default {
  /**
   * 检查版本是否对比过
   * @param {*} params 
   */
  canPrepare: function canPrepare(params) {
    return request.post("".concat(SMART_RECHECK_BASE_URL, "/canPrepare"), params);
  },

  /**
   * 预审核
   * @param {*} params 
   * @returns 
   */
  prepare: function prepare(params) {
    if (!params) return Promise.resolve({
      data: false
    });
    return request.post("".concat(SMART_RECHECK_BASE_URL, "/prepare"), params);
  },

  /**
   * 开始智能审核接口
   * @param {*} params 
   * @returns 
   */
  start: function start(params) {
    return request.post("".concat(SMART_RECHECK_BASE_URL, "/start"), params);
  },

  /**
   * 获得最新的审核记录
   * @returns 审核记录
   */
  getLatest: function getLatest(prjId) {
    return request.get("".concat(SMART_RECHECK_BASE_URL, "/latest/record?projectId=").concat(prjId));
  },

  /**
   * 获得记录详情
   * @param {*} detailId 
   * @returns 
   */
  getDetail: function getDetail(detailId) {
    return request.get("".concat(SMART_RECHECK_BASE_URL, "/detail?detailId=").concat(detailId));
  },

  /**
   * 批量获得比对记录
   * @param {String} prjId 项目id
   * @returns 
   */
  getPrjRecordList: function getPrjRecordList(prjId) {
    return request.get("".concat(SMART_RECHECK_BASE_URL, "/getPrjRecordList?projectId=").concat(prjId));
  },

  /**
   * 获得审核记录
   * @param {String}} recheckId 
   */
  getRecord: function getRecord(recheckId) {
    return request.get("".concat(SMART_RECHECK_BASE_URL, "/record?recheckId=").concat(recheckId));
  },

  /**
   * 导出Excel
   */
  exportData: function exportData(prjId, recheckId) {
    return request.post("".concat(SMART_RECHECK_BASE_URL, "/smartRecheckExport"), {
      prjId: prjId,
      recheckId: recheckId
    });
  },

  /**
   * 重新复核
   */
  reStartCheck: function reStartCheck(recheckId) {
    return request.post("".concat(SMART_RECHECK_BASE_URL, "/smartRecheck/retry/").concat(recheckId), {});
  },

  /**
   * 推送记录审核
   */
  pushToTarget: function pushToTarget(recheckId, detailId) {
    return request.post("".concat(SMART_RECHECK_BASE_URL, "/smartRecheck/pushProblemToTargetVersion/").concat(recheckId, "/").concat(detailId), {});
  }
};